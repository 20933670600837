// App.js
import React, { Suspense, useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import './App.css';
import '@aws-amplify/ui-react/styles.css';
import logo from './logo.svg';
import { Amplify, Auth, Hub } from 'aws-amplify';
import SignInComponent from './sign-in/SignIn';
import SignOut from './sign-in/SignOut';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './components/LanguageSwitcher/languageSwitcher';
import { initLightboxJS } from 'lightbox.js-react';

import FadeIn from "react-fade-in";
import LoadingPage from './LoadingPage/LoadingPage';


Amplify.configure({
  Auth: {
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_VQVIkweGP',
    userPoolWebClientId: '4a351m01gqvhqoa84f069meo1f',
  },
});

const initialFormState = {
  password: '',
  formType: 'signIn',
};

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem('isAuthenticated');
  const [user, setUser] = useState(null);

  useEffect(() => {
    initLightboxJS("72CE-A6CE-A05C-1969", "Individual");
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      Auth.currentAuthenticatedUser()
        .then(currentUser => setUser(currentUser))
        .catch(() => {/* handle error */ });
    }
  }, [isAuthenticated]);
  console.log('isAuthenticated', isAuthenticated);
  console.log('user', user);
  if (!isAuthenticated) {
    // If not authenticated, redirect to sign-in
    return <Navigate to="/sign-in" replace />;
  }

  if (!user) {
    // If user data is not yet fetched, show a loader or similar indication
    return <div className="App" style={{ backgroundColor: '#EFECE2' }}>
      <header className="App-header">
        <LoadingPage />
      </header>
    </div>;
  }

  return children;
};

const MainPageComponent = React.lazy(() => import('./MainPageComponent'));

function App() {

  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [formState, updateFormState] = useState(initialFormState);
  const [user, updateUser] = useState(null);



  const setAuthListener = useCallback(() => {
    const listener = (data) => {
      switch (data.payload.event) {
        case 'signOut':
          updateFormState((prevState) => ({ ...prevState, formType: 'signIn' }));
          updateUser(null);
          break;
        case 'signIn':
          checkUser();
          break;
        default:
          checkUser();
          break;
      }
    };
    Hub.listen('auth', listener);
    return () => Hub.remove('auth', listener);
  }, []);
  useEffect(() => {
    const checkUserOnLoad = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        updateUser(user);
        updateFormState((prevState) => ({ ...prevState, formType: 'signedIn' }));
        // Optionally, set a flag or token in localStorage here
      } catch (err) {
        // If there's no authenticated user, check localStorage
        const isAuthenticated = localStorage.getItem('isAuthenticated');
        if (isAuthenticated) {
          // Logic to handle user state based on localStorage
          // This might involve calling Auth.signIn with stored credentials
        } else {
          updateFormState((prevState) => ({ ...prevState, formType: 'signIn' }));
        }
      }
    };
    checkUserOnLoad();
    const cleanupListener = setAuthListener();
    return cleanupListener;
  }, [setAuthListener]);

  const checkUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      updateUser(user);
      updateFormState((prevState) => ({ ...prevState, formType: 'signedIn' }));
    } catch (err) {
      updateFormState((prevState) => ({ ...prevState, formType: 'signIn' }));
    }
  };


  const onChange = (e) => {
    const { name, value } = e.target;
    updateFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const signIn = async () => {
    const { password } = formState;
    try {
      const randomGuestNumber = Math.floor(Math.random() * 48) + 1;
      const username = `guest${randomGuestNumber}`;

      await Auth.signIn(username, password);
      setError(null);
      checkUser();
      localStorage.setItem('isAuthenticated', 'true');
    } catch (err) {
      console.error('Error during sign in:', err);
      setError(err.message || 'An error occurred during sign in.');
      // Do not set isAuthenticated in localStorage
    }
  };

  const handleSignOut = async () => {

    await Auth.signOut();
    updateFormState((prevState) => ({ ...prevState, formType: 'signIn' }));
    updateUser(null);
    localStorage.removeItem('isAuthenticated'); // Remove flag from localStorage on sign out
  };

  return (
    <Router>
      <Suspense fallback={<LoadingPage />}>
        <Routes>
        <Route path="/" element={
            <ProtectedRoute user={user}>
              <div className="App" style={{ backgroundColor: '#EFECE2' }}>
                <FadeIn delay={200}>
                  <MainPageComponent user={user} />
                </FadeIn>
              </div>
            </ProtectedRoute>

          } />
          <Route path="/sign-in" element={
            <div className="App" style={{ backgroundColor: '#8DBB7D' }}>
              <header className="App-header">
                <FadeIn delay={50}>
                  <LanguageSwitcher />
                  <h1>{t("We're getting married")}</h1>
                  <img src={logo} className="App-logo" alt="logo" />
                  <SignInComponent
                    onChange={onChange}
                    signIn={signIn}
                    formState={formState}
                    error={error}
                  />
                </FadeIn>
              </header>
            </div>
          } />

          <Route path="/sign-out" element={<SignOut onSignOut={handleSignOut} />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
